
const AvarusImage = []

for (let i = 0; i < 20; i++) {
    const imageSet = [...Array(5)].map((_, j) => require(`../../image/cataloge/stoleshnitsa/avarus/${i}/${j}.jpg`));
    AvarusImage.push(imageSet);
}

function createImageData(id: number, title: string, imgURL: string, pathURL: string, pathID: string, photoArray: string[], company: string, collection: string, type: string[], sizes: string[]) {
    return {
        id,
        title,
        imgURL,
        pathURL,
        pathID,
        photoArray: [...photoArray],
        company,
        collection,
        type,
        size: sizes.map(size => `${size} мм`)
    };
}

export const AvarusData = [
    createImageData(0, 'Ай Петри', AvarusImage[0][0], 'R444', 'avarus', AvarusImage[0], 'АВАРУС', 'Мраморная коллекция', ['Глянцевая', 'Матовая'], ['3050 x 1440 x 20']),
    createImageData(1, 'Московская ночь', AvarusImage[1][0], 'R677', 'avarus', AvarusImage[1], 'АВАРУС', 'Мраморная коллекция', ['Глянцевая'], ['3200 x 1600 x 20', '3050 x 1440 x 20']),
    createImageData(2, 'Белая Тайга', AvarusImage[2][0], 'R104', 'avarus', AvarusImage[2], 'АВАРУС', 'Моноколор', ['Глянцевая'], ['3200 x 1600 x 20', '3050 x 1440 x 20']),
    createImageData(3, 'Соль Каспия', AvarusImage[3][0], 'R112', 'avarus', AvarusImage[3], 'АВАРУС', 'Гранитная коллекция', ['Глянцевая'], ['3200 x 1600 x 20', '3050 x 1440 x 20']),
    createImageData(4, 'Алмазы Якутии', AvarusImage[4][0], 'R122', 'avarus', AvarusImage[4], 'АВАРУС', 'Гранитная коллекция', ['Глянцевая'], ['3200 x 1600 x 20', '3050 x 1440 x 20']),
    createImageData(5, 'Полярная ночь', AvarusImage[5][0], 'R310', 'avarus', AvarusImage[5], 'АВАРУС', 'Моноколор', ['Глянцевая'], ['3200 x 1600 x 20 мм', '3050 x 1440 x 20 мм']),
    createImageData(6, 'Скалы Карелии', AvarusImage[6][0], 'R403', 'avarus', AvarusImage[6], 'АВАРУС', 'Мраморная коллекция', ['Глянцевая', 'Матовая'], ['3200 x 1600 x 20', '3050 x 1440 x 20']),
    createImageData(7, 'Просторы Поволжья', AvarusImage[7][0], 'R558', 'avarus', AvarusImage[7], 'АВАРУС', 'Мраморная коллекция', ['Глянцевая'], ['3200 x 1600 x 20', '3050 x 1440 x 20']),
    createImageData(8, 'Жемчуг Приморья', AvarusImage[8][0], 'R500', 'avarus', AvarusImage[8], 'АВАРУС', 'Мраморная коллекция', ['Глянцевая', 'Матовая'], ['3050 x 1440 x 20']),
    createImageData(9, 'Уральские Горы', AvarusImage[9][0], 'R503', 'avarus', AvarusImage[9], 'АВАРУС', 'Мраморная коллекция', ['Глянцевая'], ['3200 x 1600 x 20', '3050 x 1440 x 20']),
    createImageData(10, 'Черное Море', AvarusImage[10][0], 'R510', 'avarus', AvarusImage[10], 'АВАРУС', 'Мраморная коллекция', ['Глянцевая', 'Матовая'], ['3200 x 1600 x 20', '3050 x 1440 x 20']),
    createImageData(11, 'Лед Байкала', AvarusImage[11][0], 'R511', 'avarus', AvarusImage[11], 'АВАРУС', 'Мраморная коллекция', ['Глянцевая', 'Матовая'], ['3200 x 1600 x 20', '3050 x 1440 x 20']),
    createImageData(12, 'Вулканы Камчатки', AvarusImage[12][0], 'R515', 'avarus', AvarusImage[12], 'АВАРУС', 'Мраморная коллекция', ['Глянцевая'], ['3200 x 1600 x 20', '3050 x 1440 x 20']),
    createImageData(13, 'Астраханские пески', AvarusImage[13][0], 'R520', 'avarus', AvarusImage[13], 'АВАРУС', 'Мраморная коллекция', ['Глянцевая'], ['3200 x 1600 x 20']),
    createImageData(14, 'Золотое кольцо', AvarusImage[14][0], 'R522', 'avarus', AvarusImage[14], 'АВАРУС', 'Мраморная коллекция', ['Глянцевая'], ['3200 x 1600 x 20', '3050 x 1440 x 20']),
    createImageData(15, 'Донская степь', AvarusImage[15][0], 'R531', 'avarus', AvarusImage[15], 'АВАРУС', 'Мраморная коллекция', ['Глянцевая'], ['3200 x 1600 x 20', '3050 x 1440 x 20']),
    createImageData(16, 'Горы Кавказа', AvarusImage[16][0], 'R538', 'avarus', AvarusImage[16], 'АВАРУС', 'Мраморная коллекция', ['Глянцевая'], ['3200 x 1600 x 20', '3050 x 1440 x 20']),
    createImageData(17, 'Снега Сибири', AvarusImage[17][0], 'R541', 'avarus', AvarusImage[17], 'АВАРУС', 'Мраморная коллекция', ['Глянцевая'], ['3200 x 1600 x 20', '3050 x 1440 x 20']),
    createImageData(18, 'Пик Эльбруса', AvarusImage[18][0], 'R543', 'avarus', AvarusImage[18], 'АВАРУС', 'Мраморная коллекция', ['Глянцевая', 'Матовая'], ['3200 x 1600 x 20', '3050 x 1440 x 20']),
    createImageData(19, 'Балтийский берег', AvarusImage[19][0], 'R555', 'avarus', AvarusImage[19], 'АВАРУС', 'Мраморная коллекция', ['Глянцевая'], ['3050 x 1440 x 20']),
];
