import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { SectionOne } from '../components/MainPage/SectionOne'
import { SectionTwo } from '../components/MainPage/SectionTwo'
import { SectionStudio } from '../components/MainPage/SectionStudio'
import { SectionThree } from '../components/MainPage/SectionThree'
import { SectionFour } from '../components/MainPage/SectionFour'
import { SectionFive } from '../components/MainPage/SectionFive'
import { SectionSeven } from '../components/MainPage/SectionSeven'
import { Footer } from '../components/Footer'
import { ContinuousTextAnimation } from '../components/animation/ContinuousTextAnimation'
import { SectionSix } from '../components/MainPage/SectionSix'

const componentsData = [
  {title:<SectionOne />},
  {title:<SectionTwo />},
  {title:<SectionStudio />},
  {title:<ContinuousTextAnimation />},
  {title:<SectionThree />},
  {title:<ContinuousTextAnimation />},
  {title:<SectionFour />},
  {title:<ContinuousTextAnimation />},
  {title:<SectionFive />},
  {title:<SectionSix />},
  {title:<SectionSeven />},
  {title:<Footer />},
]

interface PropsComponent {
    title: JSX.Element,
}

const PropsContainer: React.FC<PropsComponent> = ({title}) => {
  return title
}

const Container: React.FC = () => {
  return (
    <>
    {componentsData.map((item, index) => (
      <PropsContainer title={item.title} key={index} />
    ))}
    </>
  )
}

export const HomePage: React.FC = (type: any) => {
  const location = useLocation()

    useEffect(() => {
        
        if (location.hash) {
          const targetElement = document.querySelector(location.hash);
          if (targetElement) {
            targetElement.scrollIntoView({ behavior: 'smooth' });
          }
        }
      }, [location]);
      
  return (
    <>
      <Container />
    </>
  )
}



