import React from "react"
import { BackgroundImage, Box, Center, Text } from "@mantine/core"
import { AvarusData } from "./StoleshnitsaData"
import { IconClick } from "@tabler/icons-react"
import './Stoleshnitsa.css'

interface ComponentProps {
    id: number,
    title: string,
    imgURL: any,
    pathURL: string,
    company: string,
    pathID: string
}

const PropsContainer: React.FC<ComponentProps> = ({id, title, imgURL, pathURL, company, pathID}) => {
    return (
        <Box component="a" href={`${pathID}/${pathURL}`} key={id} h={'max-content'} className="stoleshnitsa-main">
            <BackgroundImage w={'100%'} h={270} src={imgURL} className="stoleshnitsa-image">
                <Center>
                    <Box p={0} bg={'#6C725A'} style={{borderRadius: '100%'}}>
                        <IconClick color="#FFF" size={32} style={{padding: '1rem'}}/>
                    </Box>
                </Center>
            </BackgroundImage>
            <Box w={'100%'} h={'max-content'} bg={'#10222E'}>
                <Text ff={'Roboto Condensed'} color="#FFF" fz={24} lh={'normal'} p={`1rem 0 0 1rem`}>
                    {title}
                </Text>
                <Text color="#FFF" ff={'Roboto Condensed'} fz={18} p={`0.5rem 0 1rem 1rem`}>
                    {company}
                </Text>
            </Box>
        </Box>
    )
}

export const AvarusCataloge: React.FC = () => {
    return (
        <>
        {AvarusData.map((item) => (
            <PropsContainer key={item.id} id={item.id} pathID={item.pathID} title={item.title} imgURL={item.imgURL} pathURL={item.pathURL} company={item.company}/>
        ))}
        </>
    )
}