import React, { useState } from "react"
import { BackgroundImage, Box, Button, Container, Divider, Group, Text, } from "@mantine/core"
import { changeColorButton3 } from "../styles/style"
import { ModalForm } from "./ModalForm";
import {
    IconSquareX,
    IconToolsKitchen2,
    IconHome,
    IconHanger,
    IconRectangleVertical,
    IconTable,
    IconArmchair,
    IconBath,
  } from '@tabler/icons-react';
const Logo2 = require('../image/Logotype_2.png')

const data = [
    {
        id: 0,
        title: <IconToolsKitchen2 size={48} color="#FFF" className="footer-icons"/>,
        pathURL: '/kitchen'
    },
    {
        id: 1,
        title: <IconHanger size={48} color="#FFF" className="footer-icons"/>,
        pathURL: '/garderob'
    },
    {
        id: 2,
        title: <IconRectangleVertical size={48} color="#FFF" className="footer-icons"/>,
        pathURL: '/shkaf'
        
    },
    {
        id: 3,
        title: <IconHome size={48} color="#FFF" className="footer-icons"/>,
        pathURL: '/gostinaya'
        
    },
    {
        id: 4,
        title: <IconTable size={48} color="#FFF" className="footer-icons"/>,
        pathURL: '/stoleshnitsa/avarus'
        
    },
    {
        id: 5,
        title: <IconArmchair size={48} color="#FFF" className="footer-icons"/>,
        pathURL: 'https://t.me/Nadezda236'
        
    },
    {
        id: 6,
        title: <IconBath size={48} color="#FFF" className="footer-icons"/>,
        pathURL: '/san-yzli'
    },
]

interface ComponentProps {
    id: number,
    title: JSX.Element,
    pathURL: string
}

const ComponentIcons: React.FC<ComponentProps> = ({id, title, pathURL}) => {
    return <Box key={id} p={0} component="a" href={pathURL} sx={{textDecoration: 'none'}}>{title}</Box>
}

export const Footer: React.FC = (type: any) => {
    const [modal, setModal] = useState(false)
    
    return (
        <>
            {modal &&
                <Box w={'100vw'} h={'100vh'} bg={`rgba(0, 0, 0, 0.6)`} sx={{position: 'fixed', zIndex: 10001, top: 0,}}>
                    <Button variant='transparent' p={0} m={'1rem'} onClick={() => setModal(false)} sx={{position: 'absolute', top: 0, right: 0, zIndex: 10002}}>
                        <IconSquareX size={32} color='#FFF'/>
                    </Button>
                    <ModalForm />
                </Box>
            }
            <Box w={'100%'} h={'max-content'} bg={'#6C725A'}>
                <Container maw={1180}>
                    <Group position="apart" pt={'4rem'} className="footer-group">
                            <BackgroundImage src={Logo2} w={480} h={52} mb={'1rem'} className="footer-logo"/>
                        <Button onClick={() => setModal(true)} className="footer-button" w={480} h={'4rem'} bg={'#10222E'} color="#FFF" sx={{
                            borderRadius: 10,
                            '&:hover': {
                                animation: `${changeColorButton3} 500ms`,
                                backgroundColor: '#FFF',
                                color: '#10222E'
                            },
                            }}>
                            <Text ff={'Roboto Condensed'} fw={500} lh={'normal'} lts={2}>
                                Оставьте заявку
                            </Text>
                        </Button>
                    </Group>
                    <Divider size={2} bg={'#FFF'} mt={'8rem'} className="footer-divider"/>
                    <Group position="center" my={'md'}>
                        {data.map((item) => (
                            <ComponentIcons key={item.id} id={item.id} title={item.title} pathURL={item.pathURL}/>
                        ))}
                    </Group>
                    <Divider size={2} bg={'#FFF'} />
                    <Group position="apart">
                        <Text ff={'Roboto'} fw={300} color="#FFF" lh={'normal'} mt={'2rem'} pb={'2rem'}>Студия мебели Амалия - home © 2023</Text>
                        <Text component="a" href="privacy" color="#FFF" sx={{
                            fontWeight: 600,
                        }}>Политика конфиденциальности</Text>
                    </Group>
                </Container>
            </Box>
        </>
    )
}