import { HomePage } from '../pages/HomePage';
import { KitchenPage } from '../pages/KitchenPage';
import { ShkafPage } from '../pages/ShkafPage';
import { GarderobPage } from '../pages/GarderobPage';
import { GostinayaPage } from '../pages/GostinayaPage';
import { ToiletPage } from '../pages/ToiletPage';
import { AvarusPage } from '../pages/stones/AvarusPage';
import { ProjectDetails } from '../pages/ProjectDetails';
import { Avarus } from '../components/Stoleshnitsa/Avarus';
import { Privacy } from '../pages/Privacy';

export const data = [
    {
        id: 0,
        path: '/',
        element: <HomePage />
    },
    {
        id: 1,
        path: '/kitchen',
        element: <KitchenPage />
    },
    {
        id: 2,
        path: '/shkaf',
        element: <ShkafPage />
    },
    {
        id: 3,
        path: '/garderob',
        element: <GarderobPage />
    },
    {
        id: 4,
        path: '/gostinaya',
        element: <GostinayaPage />
    },
    {
        id: 5,
        path: '/stoleshnitsa/avarus',
        element: <AvarusPage />
    },
    {
        id: 6,
        path: '/san-yzli',
        element: <ToiletPage />
    },
    {
        id: 7,
        path: 'portfolio/:pathURL',
        element: <ProjectDetails />
    },
    {
        id: 8,
        path: 'stoleshnitsa/avarus/:pathURL',
        element: <Avarus/>
    },
    {
        id: 9,
        path: 'privacy',
        element: <Privacy />
    }
]