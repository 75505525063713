import { Box, Container, Flex, Group, Text } from "@mantine/core"
import { Blocks } from '../../content/Blocks'

const VideoDesign = require('../../video/2.mp4')

export const SectionStudio: React.FC<any> = () => {
    return(
        <>
        <Box w={'100%'} h={'3rem'} bg={'#10222E'} sx={{ float: 'left' }}>
            <Flex sx={{
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <Text ff={'Roboto'} fz={24} lh={'normal'} color='#FFF' display={'block'} mt={'0.75rem'}>
                    Amalya Home
                </Text>
            </Flex>
        </Box>
        <Box w={'100%'} h={'max-content'} mb={'4rem'} sx={{
            float: 'left',
            borderTop: '8px solid #10222E'
        }}>
            <Container maw={1180} className="sec-2-container">
                <Box mt={'4rem'} className='sec-2-title'>
                    <Text ff={'Roboto Condensed'} lh={'normal'} lts={2} fz={42} fw={700} color='#10222E'>Наша студия</Text>
                    <Blocks />
                </Box>
                <Group position="apart">
                    <Flex direction={'column'} className='sec-2-box'>
                        <Text maw={541} ff={'Roboto'} fz={25} lh={'normal'} mt={52} color='#10222E' className='sec-2-text'>
                            Студия мебели "Амалия Home" — это оазис утончённого дизайна и качества, где каждый элемент интерьера создаётся с любовью и вниманием к деталям. Наша команда профессионалов разрабатывает уникальные мебельные решения, которые отражают ваш стиль и индивидуальность, придавая каждому пространству тепло и уют.
                        </Text>
                        <Text maw={541} ff={'Roboto'} fz={25} lh={'normal'} mt={52} color='#10222E' className='sec-2-text'>
                            Мы комбинируем современные тенденции с классическими традициями, используя только лучшие материалы. В "Амалия Home" мы верим, что красивая мебель способна не только украсить ваш дом, но и создать атмосферу гармонии и комфорта. Откройте для себя мир изысканной мебели, где каждая деталь имеет значение!
                        </Text>
                    </Flex>
                    <Box className='sec-2-video'>
                        <video width={'auto'} height={656} controls>
                            <source src={VideoDesign} type="video/mp4" />
                            Ваш браузер не поддерживает видео.
                        </video>
                    </Box>
                </Group>
            </Container>
        </Box>
        </>
    )
}