import React, { useRef,} from 'react';
import emailjs from '@emailjs/browser';
import { Button, Container, Group, Text, TextInput, Textarea } from '@mantine/core';

export const ModalForm = () => {
    const form = useRef();
    
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_p520a8p', 'template_jtz7cr4', form.current, 'MOgWsWR4z93iQqQ_3')
        .then((result) => {
            console.log(result.text)
            alert('Запрос отправлен!')
        }, (error) => {
            console.log(error.text)
            alert('Произошла ошибка при отправке запроса...')
        });
  };
    
    return (
        <form ref={form} onSubmit={sendEmail}>
            <Container maw={628} h={'max-content'} bg={'#FFF'} mx={'auto'} mt={'15vh'} p={'2rem'} className='contact-container'>
              <Group position='apart' pb={'2rem'}>
                <Text ff={'Roboto Condensed'} lh={'normal'} lts={2} fz={'2rem'} fw={700} color='#10222E' className='contact-title'>Обратная связь</Text>
              </Group>
              <TextInput placeholder='Ваше имя' id='name' type="text" name="user_name"/>
              <TextInput placeholder='Эл. почта' type='email' name='user_email' my={'0.5rem'}/>
              <Textarea placeholder='Сообщение' name='message' my={'1rem'} />
              <Button type="submit" bg={'#10222E'} h={48} w={'100%'} sx={{'&:hover':{backgroundColor: '#10222E'}}}>Отправить заявку</Button>
              <Text ff={'Roboto'} fz={14} fw={'lighter'} ta={'center'} w={'90%'} mx={'auto'} mt={'2rem'}>
                Нажимая кнопку <span style={{fontWeight: 'bold'}}>Отправить заявку</span> вы даете согласие на обработку персональных данных и соглашаетесь c <a href='privacy' style={{
                  color: '#6C725A'
                }}>политикой конфиденциальности</a>
              </Text>
            </Container>
        </form>
    )
  }