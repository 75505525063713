import React from 'react'
import { Box, BackgroundImage, Container, Group, Text } from '@mantine/core'
import { IconSquareCheck } from '@tabler/icons-react'
import { dataSectionFour } from '../../content/data'


interface ComponentProps {
    id: number,
    title: string
}

const PropsContainer: React.FC<ComponentProps> = ({id, title}) =>  {
    return (
        <Box key={id} h={'max-content'} sx={{borderBottom: `4px solid #FFF`}} mt={'3rem'} mb={'3rem'} mr={'2rem'} className='sec-4-block'>
            <Group position='left' mb={'1rem'}>
                <IconSquareCheck size={32} color='#FFF' className='sec-4-block-icon'/>
                <Text maw={380}  color='#FFF' fz={18} ff={'Roboto'} lh={'normal'} className='sec-4-block-text'>{title}</Text>
            </Group>
        </Box>
    )
}

const TextContainer: React.FC = () => {
    return (
        <>
        {dataSectionFour.map((item) => (
            <PropsContainer key={item.id} id={item.id} title={item.title}/>
        ))}
        </>
    )
}

export const SectionFour: React.FC = (type: any) => {
   const Section_4 = require('../../image/Section_4.jpg') 
  return (
    <Box w={'100%'} h={'max-content'} bg={'#6C725A'}>
        <Container maw={1180}>
            <Text color='#FFF' maw={360} fz={42} ff={'Roboto Condensed'} fw={700} lh={'normal'} lts={2} pt={'4rem'} className='sec-4-title'>
                Преимущества нашей компании
            </Text>
            <Text color='#FFF' className='sec-4-text' fz={24} ff={'Roboto Condensed'} fw={700} lh={'normal'} mt={'4rem'}>
                Обращаясь в нашу компанию, ВЫ получаете :
            </Text>
            <Group className='sec-4-group' h={'max-content'} position='apart' pb={'4rem'}>
                <Box w={580} h={500} sx={{overflowY: 'scroll'}} className='sec-4-scroll'>
                    <TextContainer />
                </Box>
                <BackgroundImage className='sec-4-image' src={Section_4} w={580} h={656} />
            </Group>
        </Container>
    </Box>
  )
}
