import { BackgroundImage, Box, Button, Container, Flex, Group, Text, TextInput } from "@mantine/core"
import { IconUser } from "@tabler/icons-react"
import { IconPhone } from "@tabler/icons-react"
import { changeColorButton2 } from "../../styles/style"

import emailjs from '@emailjs/browser';
import { useRef } from "react"

export const SectionSix = () => {
    const SectionImage_6 = require('../../image/Section_6.png')
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_p520a8p', 'template_f9s81xh', form.current, 'MOgWsWR4z93iQqQ_3')
          .then((result) => {
              console.log(result.text)
              alert('Запрос отправлен!')
          }, (error) => {
              console.log(error.text)
              alert('Произошла ошибка при отправке запроса...')
          });
      };

    return (
        <Box w={'100%'} h={'max-content'} bg={'#10222E'}>
            <Container maw={1180}>
                <Group position="apart" pb={'4rem'} className="sec-6-group">
                    <form ref={form} onSubmit={sendEmail}>
                        <Flex direction={'column'} w={480} className="sec-6-flex">
                            <Text ff={'Roboto Condensed'} className="sec-6-title" fz={42} color="#FFF" fw={700} lh={'normal'} lts={2} mt={'4rem'} maw={358}>
                                Рассчитаем стоимость работ
                            </Text>
                            <TextInput variant="unstyled" className="sec-6-input" w={'100%'} h={'2.5rem'} mt={'4rem'}
                                rightSection={<IconUser size={32} color="#FFF"/>}
                                sx={{borderBottom: `4px solid #FFF`}}
                                placeholder="Ваше имя"
                                styles={{input:{color: '#FFF', fontFamily: 'Roboto', fontSize: 24,}}}
                                type="text"
                                name="user_name"
                            />
                            <TextInput variant="unstyled" className="sec-6-input" w={'100%'} h={'2.5rem'} mt={'4rem'}
                                rightSection={<IconPhone size={32} color="#FFF"/>}
                                sx={{borderBottom: `4px solid #FFF`}}
                                placeholder="Email"
                                type="email"
                                name="user_email"
                                styles={{input:{color: '#FFF', fontFamily: 'Roboto', fontSize: 24,}}}
                            />
                            <Button type="submit" className="sec-6-button" h={64} mt={'6rem'} sx={{
                                backgroundColor: 'rgba(1,1,1,0)',
                                color: '#FFF',
                                border: `4px solid #FFF`,
                                borderRadius: 10,
                                '&:hover': {
                                    animation: `${changeColorButton2} 500ms`,
                                    backgroundColor: '#FFF',
                                    color: '#10222E'
                                }
                                }}>
                                <Text>Отправить заявку</Text>
                            </Button>
                            <Text className="sec-6-description" ff={'Roboto'} fz={18} color="#FFF" fw={300} lh={'normal'} mt={'2rem'}>
                                Нажимая на кнопку, вы даете согласие на обработку персональных данных и соглашаетесь c <a href="privacy" style={{
                                    color: '#6C725A'
                                }}>политикой конфиденциальности</a>
                            </Text>
                        </Flex>
                    </form>
                    <BackgroundImage className="sec-6-image" w={580} h={538} src={SectionImage_6} mt={74}/>
                </Group>
            </Container>
        </Box>
    )
}