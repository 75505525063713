import { Box, Container, Flex, Group, Grid, Image, Text, BackgroundImage } from "@mantine/core"
import { IconArrowNarrowLeft, IconX } from "@tabler/icons-react"
import {useParams} from "react-router-dom"
import { HeaderMegaMenu } from "../components/Header"
import { Footer } from "../components/Footer"
import { dataSectionFive } from "../content/data"
import { useState } from "react"
import { Carousel } from '@mantine/carousel'

export const ProjectDetails: React.FC = () => {
    const { pathURL } = useParams()
    const [isOpen, setIsOpen] = useState(false)
    
    const clickLightbox = () => {
        setIsOpen(true)
        console.log(isOpen)
    }

    return (
        <Box w={'100%'} bg={'#FFF'} h={'max-content'} sx={{ zIndex: 1 }}>
            <HeaderMegaMenu />
            <Container maw={1180} pb={'4rem'} h={'max-content'}>
                <Group position='apart' mt={'8rem'} className='cataloge-back-group'>
                        <Box component='a' href='/#portfolio' sx={{textDecoration: 'none'}}>
                            <Flex direction={'row'}>
                                <IconArrowNarrowLeft size={'2.5rem'} color='#10222E' className='cataloge-back-arrow'/>
                                <Text ff={'Roboto'} fz={24} color='#10222E' fw={400} ml={'1rem'} pt={2} className='cataloge-back-text'>Назад</Text>
                            </Flex>
                        </Box>
                </Group>
                {dataSectionFive
                    .filter((item) => item.pathURL === pathURL)
                    .map((item, index) => (
                        <Box key={index} mt={'4rem'}>
                            <Text key={index} ff={'Roboto Condensed'} mb={'2rem'} fw={500} fz={24} color='#10222E'>
                                {item.title}
                            </Text>
                            <Grid grow gutter="xs">
                                {item.photoArray.map((item, index) => (
                                    <Grid.Col lg={4} md={6} sm={12} key={index}>
                                        <Image onClick={() => clickLightbox()} src={item} sx={{
                                            cursor: 'pointer',
                                        }}/>
                                    </Grid.Col>
                                ))}
                            </Grid>
                            {
                            isOpen
                            &&
                            <Flex align={'center'} justify={'center'} w={'100%'} h={'100%'} bg={'rgba(0, 0, 0, 0.8)'} pos={'fixed'} top={0} left={0} sx={{ zIndex: 1000, cursor: 'pointer' }} className="Portfolio_flex">
                                <Box pos={'fixed'} top={0} left={0} m={'5rem'} sx={{ zIndex: 1001, cursor: 'pointer' }} onClick={() => setIsOpen(false)}>
                                    <IconX size={32} color="#FFF"/>
                                </Box>
                                <Carousel w={'55%'} mx="auto" withIndicators height={600} draggable withControls={false}>
                                    {item.photoArray.map((item, index) => (
                                    <Carousel.Slide w={'100%'} h={'100%'}>
                                        <BackgroundImage key={index} src={item} w={'100%'} h={'100%'} m={'xl'} bgsz={'cover'} />
                                    </Carousel.Slide>
                                    ))}
                                </Carousel>
                            </Flex>
                            }
                        </Box>
                    ))
                }
            </Container>
            <Footer />
        </Box>
    )
}