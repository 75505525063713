import React, { useState, } from 'react'
import {
    createStyles,
    Image,
    Header,
    HoverCard,
    Group,
    Button,
    UnstyledButton,
    Text,
    SimpleGrid,
    ThemeIcon,
    Divider,
    Center,
    Box,
    Burger,
    Drawer,
    Collapse,
    ScrollArea,
    rem,
    Container,
    Flex,
  } from '@mantine/core';
  import { useDisclosure } from '@mantine/hooks';
  import {
    IconBrandTelegram,
    IconBrandWhatsapp,
    IconMail,
    IconSquareX,
    IconChevronDown,
    IconToolsKitchen2,
    IconHome,
    IconHanger,
    IconRectangleVertical,
    IconTable,
    IconBath,
  } from '@tabler/icons-react';
  import { changeColorButton } from '../styles/style';
  import { ModalForm } from './ModalForm';
  const useStyles = createStyles((theme) => ({
    link: {
      display: 'flex',
      alignItems: 'center',
      height: '100%',
      paddingLeft: theme.spacing.md,
      paddingRight: theme.spacing.md,
      textDecoration: 'none',
      color: '#10222E',
      fontWeight: 500,
      fontSize: theme.fontSizes.sm,

      [theme.fn.smallerThan('sm')]: {
        height: rem(42),
        display: 'flex',
        alignItems: 'center',
        width: '100%',
      },

      '&:hover': {
        opacity: 0.75
      }
    },
    subLink: {
      width: '100%',
      padding: `${theme.spacing.xs} ${theme.spacing.md}`,
    //   borderRadius: theme.radius.md,
  
      // ...theme.fn.hover({
      //   backgroundColor: '#73303F'
      // }),

      '&:active': theme.activeStyles,
    },
  
    dropdownFooter: {
      backgroundColor: '#6C725A',
      margin: `calc(${theme.spacing.md} * -1)`,
      marginTop: theme.spacing.sm,
      padding: `${theme.spacing.md} calc(${theme.spacing.md} * 2)`,
      paddingBottom: theme.spacing.xl,
      
    },
  
    hiddenMobile: {
      [theme.fn.smallerThan(1150)]: {
        display: 'none',
      },
    },
  
    hiddenDesktop: {
      [theme.fn.largerThan(1150)]: {
        display: 'none',
      },
    },
  }));
  
  const mockdataAboutUs = [
    {
        icon: IconToolsKitchen2,
        title: 'Кухни',
        description: '/kitchen',
    },
    {
        icon: IconHanger,
        title: 'Гардеробные',
        description: '/garderob',
    },
    {
        icon: IconRectangleVertical,
        title: 'Шкафы',
        description: '/shkaf',
    },
    {
        icon: IconHome,
        title: 'Гостиные',
        description: '/gostinaya',
    },
    {
        icon: IconTable,
        title: 'Столешницы',
        description: '/stoleshnitsa/avarus',
    },
    {
        icon: IconBath,
        title: 'Сан. Узлы',
        description: '/san-yzli',
    },
  ];
  const socialData = [
    {
      id: 0,
      title: 'Напишите нам в Telegram',
      color: '#229ED9',
      icon: <IconBrandTelegram size={16} />,
      link: 'https://t.me/Nadezda236'
    },
    {
      id: 1,
      title: 'Напишите нам в WhatsApp',
      color: '#25d366',
      icon: <IconBrandWhatsapp size={16} />,
      link: 'https://wa.me/+79637860116'
    },
    {
      id: 2,
      title: 'Электронная почта',
      color: '#10222E',
      icon: <IconMail size={16} />,
      link: 'mailto:9637860116@mail.ru'
    },
  ]

  interface ComponentProps {
    id: number,
    title: string,
    color: string,
    icon: JSX.Element,
    link: string
  }

  const SocialLinks: React.FC<ComponentProps> = ({id, title, color, icon, link}) => {
    return (
      <Button key={id} bg={color} component='a' href={link} w={'100%'} h={48} mt={24} sx={{'&:hover': {backgroundColor: color}}}>
        <Group position='center'>
          {icon}
          <Text lh={'normal'} color='#FFF' ff={'Roboto'} fw={'bold'} className='contact-form-title'>
              {title}
          </Text>
        </Group>
      </Button>
    )
  }

  export function HeaderMegaMenu() {
    const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] = useDisclosure(false);
    const [linksOpenedAboutUs, { toggle: toggleLinksAboutUs}] = useDisclosure(false);
    const { classes } = useStyles();
    const [active, setActive] = useState(false)
    const [modal, setModal] = useState(false)
    const Logotype = require('../../src/image/Logotype.png')

    const ContactForm: React.FC = () => {
      const qrCode = require('../image/qr-code.png')
      return (
        <Box w={'100vw'} h={'100vh'} bg={`rgba(0, 0, 0, 0.6)`} sx={{position: 'fixed', zIndex: 10001, top: 0}}>
            <Container maw={628} h={'max-content'} bg={'#FFF'} mx={'auto'} mt={'8vh'} p={'2rem'} className='contact-container'>
                <Group position='apart' pb={'2rem'}>
                  <Text ff={'Roboto Condensed'} lh={'normal'} lts={2} fz={'2rem'} fw={700} color='#10222E' className='contact-title'>Контакты</Text>
                  <Button onClick={() => setActive(false)} variant='transparent' p={0}>
                    <IconSquareX size={32} color='#10222E'/>
                  </Button>
                </Group>
                {socialData.map((item) => (
                  <SocialLinks key={item.id} id={item.id} title={item.title} color={item.color} icon={item.icon} link={item.link}/>
                ))}
                <Box className={classes.hiddenMobile}>
                  <Center mt={'1.5rem'}>
                    <Image src={qrCode} maw={225} />
                  </Center>
                  <Text ff={'Roboto'} fw={500} ta={'center'} w={'90%'} mx={'auto'} mt={'sm'} color='#10222E'>
                      QR-код для обратной связи
                  </Text>
                </Box>
            </Container>
        </Box>
      )
    }


      const linksAboutUs = mockdataAboutUs.map((item) => (
        <UnstyledButton className={classes.subLink} key={item.title} mt={'0.25rem'} component='a' href={item.description} sx={{textDecoration: 'none'}}>
          <Group noWrap align="flex-start" py={'0.5rem'} px={'0.5rem'} sx={{
            '&:hover': {
              backgroundColor: '#73303F'
            },
            borderRadius: 10
          }}>
            <ThemeIcon bg={'#10222E'} size={34} radius="md">
              <item.icon size={rem(22)} color={'#FFF'} />
            </ThemeIcon>
            <Box mt={'0.5rem'}>
              <Text size="sm" ff={'Roboto'} fw={500} color="#FFF">
                {item.title}
              </Text>
            </Box>
          </Group>
        </UnstyledButton>
      ));

    return (
      <>
      <Button className='telegram-channel-btn' component='a' href="https://t.me/Amalyahome" pos={'fixed'} bottom={0} right={0} m={16} bg={'blue'} w={220} h={40} sx={{ zIndex: 500, borderRadius: 0 }}>
        <Flex direction={'row'} gap={8}>
          <Text fz={14}>
          Наш Telegram канал
          </Text>
          <IconBrandTelegram />
        </Flex>
      </Button>
      {active && <ContactForm />}
      {modal &&
        <Box w={'100vw'} h={'100vh'} bg={`rgba(0, 0, 0, 0.6)`} sx={{position: 'fixed', zIndex: 10001, top: 0,}}>
          <Button variant='transparent' p={0} m={'1rem'} onClick={() => setModal(false)} sx={{position: 'absolute', top: 0, right: 0, zIndex: 10002}}>
            <IconSquareX size={32} color='#FFF'/>
          </Button>
            <ModalForm />
        </Box>
      }
      <Box sx={{position: 'fixed', zIndex: 1000, top: 0}} w={'100%'}>
        <Header height={'6rem'} px="md" sx={{backgroundColor: '#FFF', border: 0}} className='site-header'>
          <Group position="apart" sx={{ height: '100%', maxWidth: 1180, margin: 'auto' }} >
            <Group sx={{ height: '100%' }} spacing={0} className={classes.hiddenMobile}>
              <a href='/'>
                <Image src={Logotype} maw={220} mr={'6rem'} />
              </a>
              <HoverCard width={600} position="bottom" radius="md" shadow="md" withinPortal>
                <Text component='a' href="/#company" ff={'Roboto'} fw={500} ml={'0.5rem'} className={classes.link}>
                    <Center inline>
                      <Box component="span" mr={2}>
                        Компания
                      </Box>
                    </Center>
                </Text>
                <HoverCard.Target>
                  <Text component='a' href="/#cataloge" ff={'Roboto'} fw={500} ml={'0.5rem'} className={classes.link}>
                    <Center inline>
                      <Box component="span" mr={2}>
                        Каталог
                      </Box>
                      <IconChevronDown size={16} color={'#10222E'} />
                    </Center>
                  </Text>
                </HoverCard.Target>
                <Text component='a' href="/#portfolio" ff={'Roboto'} fw={500} ml={'0.5rem'} className={classes.link}>
                    <Center inline>
                      <Box component="span" mr={2}>
                        Портфолио
                      </Box>
                    </Center>
                </Text>
                <Text component='a' href="#" ff={'Roboto'} fw={500} ml={'0.5rem'} className={classes.link}>
                    <Center inline>
                      <Box component="span" mr={2}>
                        Отзывы
                      </Box>
                    </Center>
                </Text>
                <Text onClick={() => setActive(true)} ff={'Roboto'} fw={500} ml={'0.5rem'} sx={{cursor: 'pointer'}} className={classes.link}>
                    <Center inline>
                      <Box component="span" mr={2}>
                        Контакты
                      </Box>
                    </Center>
                </Text>
                <HoverCard.Dropdown sx={{ overflow: 'hidden', borderColor: '#10222E', borderRadius: 0 }} bg={'#6C725A'}>
                  <Group position="apart" px="md">
                    <Text ff={'Roboto'} fw={500} color='#FFF'>Каталог студии Amalya-home</Text>
                    {/* <Anchor href=".sec-7-text" fz="xs" color='#FFF' ff={'Roboto'} sx={{'&:hover': {color: '#73303F'}}}>
                      Где нас найти?
                    </Anchor> */}
                  </Group>
  
                  <Divider
                    my="sm"
                    mx="-md"
                    color={'#10222E'}
                  />
  
                  <SimpleGrid cols={2} spacing={0}>
                    {linksAboutUs}
                  </SimpleGrid>

                  <Divider
                    my="sm"
                    mx="-md"
                    color={'#10222E'}
                  />

                  <div className={classes.dropdownFooter}>
                    <Group position="apart">
                      <div>
                        <Text fw={700} ff={'Roboto Condensed'} lh={'normal'} fz="sm" color='#FFF'>
                          Обратная связь
                        </Text>
                        <Text ff={'Roboto'} size="xs" color="#FFF">
                          Воспользуйтесь услугами <span style={{fontWeight: 700}}>Amalya-home</span> прямо сейчас!
                        </Text>
                      </div>
                      <Button onClick={() => setModal(true)} w={144} ff={'Roboto Condensed'} fw={500} lh={'normal'} lts={1} bg={'#10222E'} color='#FFF' sx={{
                        '&:hover': {
                          backgroundColor: '#73303F'
                        }
                      }}>Начать</Button>
                    </Group>
                  </div>
                </HoverCard.Dropdown>
              </HoverCard>
            </Group>
  
            <Group className={classes.hiddenMobile}>
                    <Center component='a' onClick={() => setModal(true)} w={225} h={50} bg={'#6C725A'} sx={{
                        textDecoration: 'none',
                        borderRadius: 10,
                        cursor: 'pointer',
                        '&:hover': {
                          animation: `${changeColorButton} 500ms`,
                          backgroundColor: '#73303F'
                        }
                        }}>
                        <Text ff={'Roboto Condensed'} fw={500} sx={{
                            color: '#FFF',
                            letterSpacing: 2,
                        }}>Оставьте заявку</Text>
                    </Center>
            </Group>

            <Group w={'100%'} position='apart' className={classes.hiddenDesktop}>
                <a href='/'>
                  <Image src={Logotype} maw={220} className='header-logo'/>
                </a>
                <Burger opened={drawerOpened} onClick={toggleDrawer} color='#000' />
            </Group>
            
          </Group>
        </Header>
  
        <Drawer.Root
          opened={drawerOpened}
          onClose={closeDrawer}
          size="100%"
          padding="md"
          title="Amalya-home"
          ff={'Roboto'}
          className={classes.hiddenDesktop}
          zIndex={10000}
          id='mantine-drawer-header'
        >
            <Drawer.Content>
                <Drawer.Header sx={{backgroundColor: '#6C725A'}}>
                    <Drawer.Title>
                        <Text fz={24} ff={'Roboto'} fw={500} color='#10222E'>
                          AMALYA-HOME
                        </Text>
                    </Drawer.Title>
                        <Drawer.CloseButton iconSize={'2.5rem'} w={'2.5rem'} h={'2.5rem'} color='red' sx={{
                          '&:hover': {
                            backgroundColor: '#6C725A',
                            color: 'white'
                          }
                        }} />
                </Drawer.Header>
                <Drawer.Body>
                    <ScrollArea h={`calc(100vh - ${rem(60)})`} mx="-md" sx={{backgroundColor: '#6C725A'}}>
                        <Divider my="sm" color={'#10222E'} />
            
                        <UnstyledButton className={classes.link} onClick={toggleLinksAboutUs} ff={'Roboto'} fw={500} fz={24}>
                        <Center inline>
                            <Box component="span" mr={5}>
                                Каталог студии
                            </Box>
                            <IconChevronDown size={16} color={'#10222E'} />
                        </Center>
                        </UnstyledButton>
                        <Collapse in={linksOpenedAboutUs}>{linksAboutUs}</Collapse>
                        
                        <Divider my="sm" color={'#10222E'} />
                        <Container>
                          <Button onClick={() => setActive(true)} w={'100%'} h={48} mt={'0.5rem'} bg={'#10222E'} sx={{'&:hover':{backgroundColor: '#10222E'}}}>
                            Контакты
                          </Button>
                        </Container>
                        <Container>
                          <Button onClick={() => setModal(true)} w={'100%'} h={48} mt={'1rem'} bg={'#10222E'} sx={{'&:hover':{backgroundColor: '#10222E'}}}>
                            Оставьте заявку
                          </Button>
                        </Container>
                        <Container>
                          <Button component='a' href="https://t.me/Amalyahome" w={'100%'} h={48} mt={'1rem'} bg={'#229ED9'} sx={{'&:hover':{backgroundColor: '#229ED9'}}}>
                            <Flex direction={'row'} gap={8}>
                              <Text>
                                Наш Telegram канал
                              </Text>
                              <IconBrandTelegram />
                            </Flex>
                          </Button>
                        </Container>
                        <Group position="center" grow pb="xl" px="md">
                        </Group>
                    </ScrollArea>
                </Drawer.Body>
            </Drawer.Content>
        </Drawer.Root>
      </Box>
      </>
    );
  }

export function Navbar(type: any) {
  return (
    <HeaderMegaMenu></HeaderMegaMenu>
  )
}
